

#footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #f0f3f6;
    padding: 14px;
    background: #fff;
    z-index: 1000;
}

#footer a {
    text-decoration: none;
}
