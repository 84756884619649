.neuronbox{
    height: 5em; 
    width: 5em;
    border-bottom: 4px solid #CDCDCD;
    border-top: 4px solid #CDCDCD;
    margin: 0px 10px 0px 0px;
    /* transition: all .2s ease-in-out;  */
}

.neuronbox:hover{
    /* border-bottom: 4px #0062FF solid; */
    /* opacity: 0.5; */
    /* transform: scale(1.5); */
   
}

.neuronbox.active{
    border-bottom: 4px #0062FF solid;
    opacity: 1;
    border-top: 4px #0062FF solid;
    /* height: 6.2em; 
    width: 6.2em; */
}

.enlargedneuron{
    height: 15.3em;
    width: 15.3em;
    border: 1px solid lightgray;
}
.viewchanneldesc{
    width: 10em;
    line-height: 1.2em;
}

.enlargeddiv{
    /* position: absolute;
    top: 2em;
    right:2em; */
    /* border: 2px solid green; */
    z-index: 6000;
    background:rgba(255, 255, 255, 0.7);
    padding: 0.0em 0.6em 0.6em 0.6em; 
}

.enlargeddesc{

}

.whitespacenowrap{
    white-space: nowrap
}

 
.layerwindow{
    max-height: 16.66em;
    /* border: 2px solid green; */
    overflow: scroll;
}
.scrollwindow{
    /* /* max-height: 16em;  */
    overflow: scroll; 
}

.scrollwindowmodel{
    max-height: 16.66em;
}

.scrollwindow::-webkit-scrollbar {
    -webkit-appearance: none;
    
}

.scrollwindow::-webkit-scrollbar:vertical {
    width: 8px;
   
}

.scrollwindow::-webkit-scrollbar:horizontal {
    height: 8px;
}

.scrollwindow::-webkit-scrollbar-thumb{
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}
::-webkit-scrollbar-corner {
    background-color: rgba(0, 0, 0, 0);
}

.twitterbutton{
    /* background: rgb(105, 105, 105) ; */
    /* padding: 10px; */
    display: block;
    /* color: white; */
    text-decoration: none;
    font-weight: bold;
}

.neurondivbox{
    max-height: 24em; 
}

.curatedboximg{
    height: 2.5em;
    width: 2.5em;
}

.curatedboximg:hover{
   opacity: 0.6;
}
.curatedboximg{
    opacity: 1
 }

 .curatedneuronbox{
    height: 8em; 
    width: 8em;
    border-bottom: 4px solid #CDCDCD;
    border-top: 4px solid #CDCDCD;
    margin: 0px 10px 0px 0px;
    /* transition: all .2s ease-in-out;  */
}