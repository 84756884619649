.datasetbox{
    height: 5em; 
    width: 5em;
    border-bottom: 4px solid #CDCDCD;
    border-top: 4px solid #CDCDCD;
    margin: 0px 10px 0px 0px;
    opacity: 1;
    
}
.datasetbox:hover{
    /* border-bottom: 4px #0062FF solid; */
    opacity: 0.7;
   
}

.datasetbox.active{
    border-bottom: 4px #0062FF solid;
    border-top: 4px #0062FF solid;
    opacity: 1;
    /* height: 6.2em; 
    width: 6.2em; */
}
.maxh16{
    max-height:  12em;
    overflow: scroll;
}
.mynotif{
    border-left: 4px  #054ADA solid;
}

.datasettitles{
    margin: 0px 0px 5px 0px;
    font-weight: bold;
    font-size: 0.72em;
}

.datasetfullbox:hover{
    /* background-color: blue; */
}

.dsselected {
    border-bottom: 4px #0062FF solid;
}

.mainsimilarityimage{
    width: 19.5em;
    border-bottom: 4px #0062FF solid;
    height: 13.45em;
    object-fit: cover;
}
.mainsimilaritydesc{
    /* font-size: 1em;
    line-height: 1.28em;  */
}

.glowbar { 
    height: 0.24em;
    background: #0062FF ;
}

.mainsimilaritytitle{
    position: absolute;
    bottom: .44em;
    left: 0px;
    width: 100%;
    background: rgba(250, 250, 250, 0.7);
}

.topmainscore{
    font-size: 2.5em;
    font-weight: bold;
    width: 4.5em;
    text-align: center;
    padding: 12px 12px 5px 12px;
    /* border: 1px solid black; */
}
.topscorediv{
    background: #CDCDCD;
    /* height: 9em; */
    /* border: 1px solid black; */
}
.weightedscore{
    padding: 4px 0px 4px 0px;
    border-top: 1px solid grey;
    /* height: 20px; */
}
.wscore{
    line-height: 1.3em;
}

.simiimage{
    /* width: 5em;  */
    height: 5em; 

    margin: 0px 0px 0px 0px; 
    border-bottom: 4px solid #CDCDCD;
    /* object-fit: cover; */
    /* transition: all .2s ease-in-out;  */
}


.simiimage:hover{
    opacity: 0.3;
    border-bottom: 4px #0062FF solid;
    /* transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s; */
    /* transform: scale(1.5); */
}
.simiimage.active{
    border-bottom: 4px #0062FF solid;
    opacity: 1;
    /* height: 6.2em; 
    width: 6.2em; */
}
.dispblock{
    display: block;
}

.outersimbar{
    background: rgb(120, 168, 247);
    height: 4px;
}

.outersimbarorange{
    height: 4px;
    background:  rgb(255, 166,0, 0.5);
}

.innersimbar{
    background: #0062FF;
    height: 4px;
}


.innersimbarorange{
    background: rgb(255, 166, 0);
    height: 4px;
}

.similarityscorebox{
    position: absolute;
    top:3em;
    left: 0em;
    font-weight: bold;
    pointer-events: none;
    width: 100%;
    text-align: center;
    opacity: 0;
}

.similarityfullbox:hover > .similarityscorebox{
    opacity: 1;

}

.smalldesc{
    font-size: 0.72em;

}


.orientationmodal{
    /* width: 180%; */
}
div.bx--modal{ 
}
div.bx--modal-container{
    width: 100%; 
}

.showmore{ 
    padding: 7px;
    margin: 0px 0px 5px 0px
}

.showmore:hover{
    background: rgba(209, 209, 209, 0.9);
}
 
.modelconfigbutton{
    background: rgba(209, 209, 209, 0.9);
}
.modelconfigdiv{
    background:  rgba(209, 209, 209, 0.3);
   
}
.comparediv{
    min-height: 400px;;
}
.mainsemanticdiv{
    min-width: 360px;
    /* border: 1px solid black; */
}

.greenmoreinfo{
    background: rgba(37, 161, 72, 1);
    color: white;
}
.greenmoreinfo:hover{
    background: rgb(34, 153, 68);
}

.greenmoreinfo:active{
    background: rgb(27, 110, 49);
}

.greymoreinfo{
    background: rgba(209, 209, 209, 0.9);
}
.greymoreinfo:hover{
    background: rgb(187, 187, 187);
}
.greymoreinfo:active{
    background: rgb(155, 155, 155);
}

.greytab.active{
    /* background: rgb(155, 155, 155); */
    /* color:white; */
    border-bottom: 4px solid #0062FF;;
}

.greytab{
    border-bottom: 4px solid #CDCDCD;;
}

.modelconfigdiv > .layerwindow{
    background:  rgba(209, 209, 209, 0.3);
}

.advancedgreyborder{
    background:  rgba(209, 209, 209, 0.5);
    margin: 0px 7px 0px 0px;
    /* border: 1px solid  rgba(209, 209, 209, 0.9); */
}
.advancedoptionsbox{
    /* min-width: 50em; */
}

.minwidth485{
    /* min-width: 232px; */
}

.metrictitle{
    top: 3.7em;
    left:0em;
    width: 100%;
    text-align: center; 
    font-size: 1.1em;
    font-weight: bold;
    z-index: 10; 
    pointer-events: none;
}

.datasetdivbox{
    max-height: 36em;
    overflow: scroll;
}

.datasetclassbar{
    height: 0.4em;
    background: rgba(161, 161, 161, 0.9);
    min-width: 3em;
}
.datasetclassbar.active{
    background: #0062FF;
}


.datasetclasslist:hover .datasetclassbar{
    background: #0062FF;
}

.categorytitle{ 
    z-index: 100000;  
    border-bottom: 3px solid rgb(85, 85, 85);
    background: rgb(85, 85, 85);
    color: white;
    padding: 7px 8px 5px 7px;
}

.categorybox{
    height: 3.0em;
    width: 100%;  
}
.categorymain{
    /* float: left; */
}
.sliderbox.topconfig.open { 
    height: 14.3em;
}

