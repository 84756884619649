.answerarea{
    padding: 0.6em 0.6em 0.8em 0.6em;
    background: rgba(179, 177, 177, 0.3);
}

.qimg{
    max-height: 16em;
    width: 100%;
    height: 100%;
    object-fit: cover;
}