.zback{
    z-index: -10000;
}

.svgbox{
    border: 1px solid green;
    margin-left: 5px;
}

.overallbest{
    background: rgba(66, 248, 136, 0.801) ;
   fill: green;  
}

.maincompareimg{
    height: 150px; 
    width: 220px;
    object-fit: cover;
    margin: 0px 0px 0px 0px;
    border-bottom: 4px #0062FF solid;
    
    /* width: 5em;  */
}

.maincomparebox{ 
   /* height: 100%; */ 
   margin: 0px 0px 0px 15px;
}

.maincomparetitle{ 
   padding: 10px ;
   background: rgba(255, 255, 255, 0.95);
}

.displayinvisible{
    visibility: hidden;
}

.loadingdiv{
    /* position: absolute; */
    /* top: 15px;
    left: 10px; */
}

.legbox{
   padding: 5px 5px 5px 5px; 
   margin: 0px 10px 5px 0px;
   border: 1px solid rgba(128, 128, 128, 0.301);
}

.legheader{
    margin: 0px 0px 10px 0px;
}